import React, { useEffect, useRef } from 'react'
import Layout from "@components/Layout"
import Seo from "@components/SEO"
import Box from '@components/Box'
import Image from '@components/Image'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import ArtDecoContainer from '@components/ArtDecoContainer'
import { H2, Paragraph, Section } from '@components/RichTextAndTypography'
import theme from '@themes/index'
import botsWanderingWebm from '@images/game-features/video-bots-wandering.webm'
import botsWanderingMp4 from '@images/game-features/video-bots-wandering.mp4'
import elevatorDownWebm from '@images/game-features/video-elevator-down.webm'
import elevatorDownMp4 from '@images/game-features/video-elevator-down.mp4'
import furnishingHomeWebm from '@images/game-features/video-furnishing-home.webm'
import furnishingHomeMp4 from '@images/game-features/video-furnishing-home.mp4'
import plantsGrowingWebm from '@images/game-features/video-plants-growing.webm'
import plantsGrowingMp4 from '@images/game-features/video-plants-growing.mp4'
import PVPDodgeWebm from '@images/game-features/video-PVP-dodge.webm'
import PVPDodgeMp4 from '@images/game-features/video-PVP-dodge.mp4'
import { useInView } from 'framer-motion'



const PrivacyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bgXL: file(relativePath: {eq: "game-features/background-xl-v2.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 60
            breakpoints: [1360, 1920, 2880]
          )
        }
      }
      bgTablet: file(relativePath: {eq: "game-features/background-tablet-v2.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 60
            breakpoints: [768, 1024, 1360, 1920]
          )
        }
      }
      bgMobile: file(relativePath: {eq: "game-features/background-mobile-v2.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [JPG, WEBP]
            quality: 60
            breakpoints: [768, 1024, 1360, 1920]
          )
        }
      }
    }
  `)

  const background = withArtDirection(getImage(data?.bgXL), [
    {
      media: '(max-width: 639px)',
      image: getImage(data?.bgMobile)
    },
    {
      media: '(max-width: 1023px)',
      image: getImage(data?.bgTablet)
    },
  ])

  const bgGradients = {
    mobile: "linear-gradient(5deg, rgba(0, 0, 0, 0.5) 18%, rgba(0, 0, 0, 0) 58%), linear-gradient(197deg, rgba(0, 0, 0, 0.8) 16%, rgba(0, 0, 0, 0) 30%)",
    tablet: "linear-gradient(5deg, rgba(0, 0, 0, 0.5) 18%, rgba(0, 0, 0, 0) 58%), linear-gradient(233deg, rgba(0, 0, 0, 0.8) 21%, rgba(0, 0, 0, 0) 66%)",
    desktop: "linear-gradient(5deg, rgba(0, 0, 0, 0.5) 18%, rgba(0, 0, 0, 0) 58%), linear-gradient(233deg, rgba(0, 0, 0, 0.8) 21%, rgba(0, 0, 0, 0) 66%)",
    xl: "linear-gradient(5deg, rgba(0, 0, 0, 0.5) 18%, rgba(0, 0, 0, 0) 58%), linear-gradient(233deg, rgba(0, 0, 0, 0.7) 21%, rgba(0, 0, 0, 0) 66%)"
  }


  return (
  <Layout>
<Box position="relative">
      <Image
        image={background}
        alt=""
        width="100%"
        height="100%"
        position="absolute"
        objectPosition='top left'
        zIndex="-100"
      />
      <Box
        width="100%"
        height="100%"
        position="absolute"
        background={[
          bgGradients.mobile,
          null,
          bgGradients.tablet,
          null,
          bgGradients.desktop,
          null,
          bgGradients.xl
        ]}
        zIndex="-100"
      />
      <Box
        width="100%"
        height="100%"
        position="absolute"
        background={[
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 10%)",
          null,
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 10%)",
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 12%, rgba(0, 0, 0, 0) 28%)",
          null,
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 24%)",
          "linear-gradient(0deg, rgba(0, 0, 0, 1) 8%, rgba(0, 0, 0, 0) 20%)",
        ]}
        backgroundPosition="bottom"
        zIndex="-100"
      />
<Section
      width="100%"
      textAlign="center"
      position="relative"
      animated
      initial="hidden"
      whileInView="visible"
    >
      <Box
        width="100%"
        pt={["64px", null, null, null, '16px', null, '200px']}
        pb={[null, null, '128px']}
        px={[
          "32px",
          null,
          null,
          '48px',
          "64px",
          `max(128px, calc(((100vw - ${theme.sizes.maxWidth.xxlarge}) * .5)))`
        ]}
        m="0 auto"
        display="flex"
        alignItems="center"
        flexDirection="column"
        gridRowGap="64px"
        
      >
          <ArtDecoContainer
            // key={`${i}-game-feature`}
            width="100%"
            marginTop={["120px", '180px']}

          >
            <Box
              px={[null, null, null, "64px"]}
              py={[null, null, null, "32px", "48px"]}
              display="flex"
              flexDirection={[
                'column',
                null,
                null,
               'row'
              ]}
              alignItems="center"
              justifyContent="center"
              gridColumnGap={[null, null, null, "64px", "128px"]}
              gridRowGap={["32px", null, "64px"]}
            >
              <Box
                flex={[null, null, null, "1"]}
                // maxWidth={["calc(420px + 128px)", null, "calc(420px + 256px)", "35%", "380px"]}
                px={["64px", null, "128px", 'initial']}
                textAlign="left"
                fontSize="20px"
                maxWidth='-webkit-fill-available'
              >
                <H2
                  fontSize="40px"
                  color="white"
                  mb="16px"
                >
                  Privacy Policy
                </H2>
                {/* <span>Effective date: November 3, 2022</span>


<p>ALG Publishing, LLC dba A List Games (“us”, “we”, “ALG”, or alternative first-person reference) wants to thank you for your interest in connection with our video games, particularly “Hell is Others” and “Kingshunt” (available for download on Steam, the Epic Games Store, the PlayStation Store and the Microsoft Store on Xbox) (the “Game(s)”), our website at https://www.alistgames.net (the “Website”), our products, content, events, applications, patches, updates, tools or services (each individually a “Service” and collectively the “Services”).).  If you are here, you have either been directed here through our Services, or through our associated social media, including Facebook, Discord, Twitter, Instagram, YouTube, Twitch, TikTok and other online communities or website(s).

This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Services, your rights to your data under US and International Law, including but not limited to the EU Directive 2002/58/EC and the EU General Data Protection Regulation (“GDPR”), South Korea’s Personal Information Protection Act (“PIPA”), Brazil’s Lei Geral de Proteção de Dados (commonly referred to as the “LGPD”), the Controlling the Assault of Non-Solicited Pornography and Marketing Act of 2003 (“CAN-SPAM”), the US Children’s Online Privacy Protection Act (“COPPA”), and the California Consumer Privacy Act (“CCPA”), and the choices you have associated with that data. This Privacy Policy applies to the Websites and Services, regardless of how you access or use them, including through mobile devices. This Privacy Policy does not apply to any third-party websites, services or applications, even if they are accessible through our Services. 

We use your data to provide and improve the Services. This Privacy Policy is solely intended to inform you of our data collection practices and your rights to any of your data that we may collect.
</p>
<p>This Privacy Policy describes:</p>
<ul>
<li>●	The information we collect, how we collect such information and the purposes of our collection;</li>
<li>●	How we use and with whom we share such information;</li>
<li>●	How you can access and update your information; and</li>
<li>●	How we protect the information we store about you.</li>
</ul>
<p>Disclosure to California Residents: In our efforts to comply with applicable regulation, we ask and advise that California residents review the “CCPA Disclosure” at the end of this document. Although we do not sell User data, we will provide notice if at any time those circumstances change.

Disclosure to Residents of Brazil: The LGPD grants residents of Brazil certain rights about the processing of their personal information and we ask and advise that residents of Brazil review the relevant section under the “Your Rights” heading of this Privacy Policy.


Definitions

Personal Data
Personal Data means information relating to an identified or identifiable individual. An identifiable individual is one who can be identified, directly or indirectly, by reference to an identifier such as a name, a device identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person. 

Usage Data
Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).

Cookies
Cookies are small pieces of data stored on a User’s device.

Data Controller and Data Protection Officer
Data Controller means a person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any Personal Data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data. Where we have a legal obligation to do so, we have appointed data protection officers (DPOs) to be responsible for our privacy program. You can contact our DPO via the Contact Us information detailed below in this Privacy Policy.

Data Processor (or Service Providers)
Data Processor (or Service Provider) means any person (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.

Data Subject
Data Subject is any living individual who is the subject of Personal Data.

User
The User is you - the individual using our Service! The User corresponds to the Data Subject, who is the subject of Personal Data.


Information Collection and Use
We collect different types of information for various purposes to provide and improve our Service to you.

Types of Data Collected

Personal Data
While using our Services or when contacting us through our Website (for example, if you have questions about our Services) we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you for customer support “Personal Data”). 

Personally identifiable information may include, but is not limited to:
</p>
<ul>
<li>●	E-mail addresses for customer support, surveys, playtests and other promotional communications. We will also store customer support correspondence and any information contained within in order to service your customer support requests and maintain a record of error and bug resolutions.</li>
<li>●	Name of the platform the User utilizes to access the Games (such as Windows, PlayStation or Xbox)</li>
<li>●	Steam ID, PlayStation Network ID, Xbox gamertag, or any first party authentication ID we may add to allow Users to sign into the Game)</li>
<li>●	Video game specific username and password</li>
<li>●	Hardware specifications such as CPU vendor and brand, and GPU Brand</li>
<li>●	IP Address</li>
</ul>
<p>
We may also use your Personal Data to contact you with newsletters, updates, marketing or promotional materials and other information that may be of interest to you in relation to our Services. You may opt in to receiving any, or all, of these communications from us by providing your e-mail to subscribe to our mailing list.

Usage Data
We, or third parties operating through our website (such as analytics providers like Google Analytics), may also collect information concerning your access and use of the Services (“Usage Data”). This Usage Data may include certain technical information about your mobile device or computer system such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, system unique device identifiers, as well as interactions between you and the Services, such as what character you choose, play history,  how long you are in a match and whether you win or lose, what you click on in the UI, what pages in the application you visit and other diagnostic data.

Location Data
When you use our Services, we may collect, use and store information about your general location by converting your IP address into a rough geo-location (“Location Data”) to provide features to our Services, such as to provide matchmaking in our Games and for country mapping-purposes to display the appropriate currency in your region, and to improve and customize our Services.

Tracking Cookies Data
We, or third parties acting on our or their own behalf may use cookies and similar tracking technologies to track your activity in connection with our Services and hold certain information.

Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Cookies help us provide the appropriate experiences and features of our Services, analyze and improve how the Services are being accessed and used, and enable us to track performance of the Services. Third parties acting on our behalf may also use cookies to gather information about your activities on the Services, and other sites you visit, in order to provide you with ads. 

Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.

You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Services.


Use of Data

We use your personal information to provide you with our Services, to comply with our legal obligations, protect your vital interest, or as may be required for the public good. This includes:
</p>
<ul>
<li>●	To provide and maintain our Services;</li>
<li>●	To notify you about changes to our Services;</li>
<li>●	To allow you to participate in interactive features of our Services when you choose to do so and to the extent applicable;</li>
<li>●	To provide customer support; and</li>
<li>●	To prevent, detect, mitigate, and investigate fraud, security breaches or other potentially prohibited or illegal activities</li>
</ul><p>
We also use your personal information to pursue our legitimate interests where your rights and freedoms do not outweigh these interests. We have implemented controls to balance our interests with your rights. This includes to:
</p>
<ul>
<li>●	To improve gameplay and delivery with regard to our Services;</li>
<li>●	To use generalized location information to provide you with location-based services;</li>
<li>●	Gather analysis or valuable information through interaction data and measuring business outcomes, performance and behavior so that we can improve our Services; </li>
<li>●	Monitor the usage of our Services;</li>
<li>●	Detect, prevent and address technical issues; and</li>
<li>●	To enforce any End User License Agreement and/or Terms of Service with respect to our Services, to the extent applicable.</li>
</ul>
<p>
With your consent, we may use your personal information to:
</p>
<ul>
<li>●	Provide you with updates concerning our Game or future titles; </li>
<li>●	Provide you with news, special offers and general information about other services, promotions, or events, unless you have opted not to receive such information; and</li>
<li>●	Contact you in connection with prior customer support or Services you have experienced.</li>
</ul>
<p>
If you have provided your e-mail address to us (e.g., via a customer support request, through our playtest signups or in relation to our mailing list), we may use it to respond to customer support inquiries or to send promotional e-mail messages directly or in partnership with parties other than ALG.

Please contact us in the event that you no longer wish to receive such communications.


Retention of Data

ALG will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.

ALG will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Services, or we are legally or contractually obligated to retain this data for longer time periods.


Transfer of Data

Your information, including Personal Data, may be transferred to — and maintained on — cloud storage, or otherwise on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.

If you are located outside of the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process it there.

ALG will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of your data and other personal information. The transfer of such data is typically for the purpose of fulfilling our contract with you, as specified above. Therefore, any request to delete or modify such Personal Data may result in the termination or interruption of our Services with respect to your usage of such Services.


Disclosure of Data

Disclosure for Law Enforcement
Under certain circumstances, ALG may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).

Legal Requirements
ALG may disclose your Personal Data in the good faith belief that such action is necessary to:
<ul>
<li>●	To comply with a legal obligation;</li>
<li>●	To protect and defend the rights or property of ALG;</li>
<li>●	To prevent or investigate possible wrongdoing in connection with the Service;</li>
<li>●	To protect the personal safety of Users of the Service or the public; and</li>
<li>●	To protect against legal liability.</li>
</ul>
Change of Control
If we are subject to a merger or acquisition with/by another company, we may share information with them in accordance with our privacy standards. Should such an event occur, we will require that the new combined entity follow this privacy policy with respect to your Personal Data. If we intend to handle your Personal Data for any purposes not covered in this privacy notice, you will receive prior notification of the processing of your Personal Data for the new purposes.


Security of Data

We protect your Personal Data using technical and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use may include data encryption and information access authorization controls. The security of your Personal Data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.


"Do Not Track" Signals

California Do Not Track Disclosure 
In September 2013, California enacted amendments to the California Online Privacy Protection Act (California Business & Professions Code §§22575-22579) which require operators of websites to disclose how they respond to “do not track” (DNT) signals from browsers used by consumers or other mechanisms that provide consumers a choice regarding the collection of personally identifiable information about the consumer’s online activities over time and across different websites or online services. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.

While some newer browsers have incorporated “Do Not Track” features which, when turned on, send a signal or preference to the websites you visit indicating that you do not wish your online activity to be tracked, a DNT protocol that can be implemented across multiple devices and sites has not yet been widely adopted. Currently, we do not respond, and cannot act on DNT signal headers that we may receive; accordingly, the choices that we provide you concerning our collection and use of personally identifiable information will continue to operate as described in this policy.


Your Rights

ALG aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.

You may update, correct, or provide additional information to your Personal Data by resubmitting your information or contacting us directly. If you are correcting information provided to a third party (such as those identified in the “Service Providers” section below) please correct your information via the methods and direction provided by the third party.

If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us via any of the contact options provided at the end of this Privacy Policy.

You have the right:

<li>●	To know what personal information we maintain about you;</li>
<li>●	To receive a copy of your personal information in a structured, commonly used and machine-readable or commonly used electronic format on request;</li>
<li>●	To update and modify personal information that is incorrect or incomplete;</li>
<li>●	To object to our processing of your personal information when collection is based on a legitimate interest; and</li>
<li>●	To delete or restrict how we use your personal information, but this right is determined by applicable law and may impact your access to some of our Services.</li>

Please note that we may ask you to verify your identity before responding to such requests.

How to Control Your Information
Under the EU General Data Protection Regulation, individuals residing in the EU and other territories that have adopted GDPR compliance or comparable regulation have a right to:
<ul>
<li>●	Access their User data;</li>
<li>●	Correct the data they have provided to us;</li>
<li>●	Have information provided to them in a portable format;</li>
<li>●	Request the deletion of the data we have collected;</li>
<li>●	Restrict processing; and</li>
<li>●	Object to processing or the legal basis on which we rely to process their data.</li>
</ul>
Please note that we may ask you to verify your identity before responding to such requests.

You may send an e-mail to contact@alistgames.net with your request to exercise any of the rights identified above. Place the reason for your request (e.g. “Delete my Data” or “Correct my Data”) in the subject line and include your first name, last name, Platform ID and e-mail address in the body of the e-mail. We will respond to your request within thirty (30) days. Please note that certain records, for example those pertaining to payments or customer service matters, will be retained for legal and accounting purposes. If you have sent or posted content through the Services, we may not be able to delete it. 

We are unable to delete data controlled solely by third parties; for example, if you purchase our Games through the PlayStation Store, we won’t be able to delete your Game from your device, nor the data collected by Sony in connection with your purchase or your PlayStation Network ID. Similarly, if you access any of our Services through third party social media or service platforms, such as Twitter, or if you are routed to a third-party service provider’s web page through our Services, you will need to make any deletion requests to those respective third parties separately.

Individuals Located in Brazil: The LGPD grants you certain rights about the processing of your personal information. In addition to any applicable rights mentioned above, eligible residents of Brazil also have the right: (i) to the anonymization, blocking, or elimination of your personal information, if you believe we are processing your personal information in an unnecessary, exceeding or non-compliant manner with the LGPD; (ii) to request the portability of your personal information; (iii) to request information about the entities with which we have shared your personal information; and (iv) to request information about the possibility and consequences of denying consent. While our processing of personal information generally does not include automated decision-making, you are also entitled to a review of our decisions that are made solely based on automated processing of personal information that affect your interests. Notwithstanding any other references to the contrary within this Privacy Policy, in accordance with the LGPD, we will respond to your request within fifteen (15) days after receipt and we will provide any requested information free of charge to you. You may also file a complaint with the National Data Protection Authority in Brazil, the Autoridade Nacional de Proteção de Dados (“ANPD”) for alleged violations of the LGPD, in addition to or alternatively to seeking judicial redress.


Social Media Communications

You may be able to take part in certain activities through our Services that give you the opportunity to communicate or share information not just with ALG, but also with other Users of our Services, generally by engaging with official ALG social media accounts. We are not responsible for your use of those services. If you communicate to us or other Users publicly via our social media accounts, you are strongly encouraged to review the privacy policies of those services if you have questions about the use of your data. We only access the information you make available to the public on our social media pages and do not receive any information concerning your social media account from any social media platform.


Service Providers

We may employ third party companies and individuals to facilitate our Services (“Service Providers”), to provide the Services on our behalf, to perform Service-related services or to assist us in analyzing how our Services are used.

These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. Some of these tasks include data analysis, hosting services, customer service and to assist us in our promotional efforts.

Web Services, Payment Processing and Analytics
We may use the following third-party Service Providers to monitor, analyze and improve the use of our Service. Please see the links to their respective privacy policies as follows:

Strelka Games	https://www.iubenda.com/privacy-policy/21823212
GameAnalytics	https://gameanalytics.com/privacy/
Azure PlayFab	https://privacy.microsoft.com/en-us/privacystatement
Gamesight	https://console.gamesight.io/privacy-policy
Exit Games	https://dashboard.photonengine.com/en-US/account/privacyandcookiepolicy
Atlassian - Jira	https://www.atlassian.com/legal/privacy-policy
Steam	https://store.steampowered.com/privacy_agreement/
Epic Games	https://www.epicgames.com/site/en-US/privacypolicy
PlayStation	https://www.playstation.com/en-us/legal/privacy-policy/
Xbox	https://privacy.microsoft.com/en-us/privacystatement
Gmail	https://policies.google.com/privacy?gl=US&hl=en
Twitter	https://twitter.com/privacy
Discord	https://discordapp.com/privacy
Facebook	https://www.facebook.com/policy.php
Twitch	https://www.twitch.tv/p/en/legal/privacy-notice/
YouTube	https://policies.google.com/privacy
TikTok	https://www.tiktok.com/legal/privacy-policy-us?lang=en
Instagram	https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect


Links to Other Sites

Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.


Children’s Privacy

Our Services are intended for a general audience and are not intended for children under the age of 13, or under the applicable age of majority in your country of residence. If we become aware that we have unknowingly collected Personal Data from children, we take steps to remove that information from our servers. We urge parents to instruct their children to never give out their real names, addresses or phone numbers without permission. We recognize a special obligation to protect Personal Data obtained from young children and strive to comply with the Children’s Online Privacy Protection Act of 1998, the US law that protects the privacy and information of children. We strongly encourage parents and guardians to learn more about this important regulation. For more information, you can visit: https://epic.org/privacy/kids/#introduction. 

We understand that children may also use our website without directly providing Personal Data. We may, directly or through a third-party service provider, use javascript, cookies, web beacons, and other similar technologies described above to collect information about your or your child's use of the Services. We use these technologies for measurement, analytics, and to improve our Services. For example, we use these technologies to help us recognize your or your child's browser as a previous User and save any preferences that may have been set; to help us customize the content shown while you use, or your child uses the Services and to help measure and research the effectiveness of our Services. We may also aggregate your or your child's information with information about other Users in a way that does not directly identify you or your child, and may share aggregated or de-identified information with third parties, including to help us develop, analyze and improve content and Services. It is possible that the third parties may use this aggregated or de-identified information to develop their own products and service offerings.

We may also allow third parties to place and read their own cookies, web beacons, and similar technologies to collect information through the Services, including to help us with traffic measurement, research, and analytics. We permit these third parties only to collect cookie information and similar identifiers that do not, on their own, directly identify you or your child. You may be able to refuse or disable cookies by adjusting your web browser settings. Because each web browser is different, please consult the instructions provided by your web browser (typically in the "help" section). If you choose to refuse, disable, or delete these technologies, some of the functionality of our Services may no longer be available to you or your child.


Changes to This Privacy Policy

We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via e-mail and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.

You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.


Contact Us

If you have any questions about this Privacy Policy, please contact us:
</p>
<ul>
<li>●	Reach our Data Protection Officer by e-mail: contact@alistgames.net</li>
<li>●	By visiting this page on our website: https://www.alistgames.net/contact</li>
<li>●	Or by mail at: 49 E Walnut St, Pasadena, CA 91103</li>
</ul>
<p>
California Consumer Data Rights

A List Games complies with the California Consumer Privacy Act of 2018 (CCPA), effective January 1, 2020. This landmark piece of legislation secures new privacy rights for California consumers.

Categories of Data We Collect and Their Uses

In addition to the details provided in our Privacy Policy, we collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“Personal Information”). In particular, we have collected the following categories of Personal Information from consumers within the last twelve (12) months:

Category	Examples	Collected	Reason for Collection	Is Information “Sold” to 3rd Pty?
Identifiers.	A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, e-mail address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.	Yes	E-mail addresses for customer support and promotional communications, Steam ID, PlayStation Network ID, Xbox gamertag or any first party authentication ID we may add to allow Users to sign into the Game), IP addresses for in-Game matchmaking services, name of the platform the User utilizes to access the Games (such as Windows, PlayStation or Xbox), and hardware specifications such as CPU vendor and brand, and GPU Brand.
	No
Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).	A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.	No	N/A	N/A
Protected classification characteristics under California or federal law.	Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).	No	N/A	N/A
Commercial information.	Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.	No	N/A	N/A
Biometric information.	Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.	No	N/A	N/A
Internet or other similar network activity.	Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.	Yes	To determine if a visitor to the Website or viewers of ads purchase a product. 	No
Geolocation data.	Physical location or movements.	Yes	IP addresses are converted into a rough geo-location data (region) to provide in-Game matchmaking services.	No
Sensory data.	Audio, electronic, visual, thermal, olfactory, or similar information.	No	N/A	N/A
Professional or employment-related information.	Current or past job history or performance evaluations.	No	N/A	N/A
Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).	Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.	No	N/A	N/A
Inferences drawn from other personal information.	Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.	No	N/A	N/A


Personal Information does not include:
</p>
<ul>
<li>●	Publicly available information from government records.</li>
<li>●	De-identified or aggregated consumer information.</li>
<li>●	Information excluded from the CCPA's scope, like:</li>
o	Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;
o	Personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
</ul>
<p>
We obtain the categories of Personal Information listed above from the following categories of sources:
</p>
<ul>
<li>●	Directly from our Users. For example, from customers who engage us for customer support or provide customer feedback.</li>
<li>●	Indirectly from our Users. For example, through information we collect from our Users in the course of providing customer support to them.</li>
<li>●	Directly and indirectly from activity on our Websites or through the Services. For example, from APIs and cookies (described above) embedded in our Websites, as applicable, and other information related to improving our products.</li>
<li>●	From third parties that interact with us in connection with the Services we perform. </li>
</ul>
<p>
All such Personal Information is used as otherwise described above in our Privacy Policy. 


Sharing Personal Information

We may disclose your Personal Information to a third party for a business purpose.  When we disclose Personal Information for a business purpose, we enter into a contract that describes the purpose and requires the recipient to both keep that Personal Information confidential and not use it for any purpose except performing the contract.


Your Rights and Choices 

Access to Specific Information and Data Portability Rights

You have the right to request that we disclose certain information to you about our collection and use of your Personal Information over the past twelve (12) months. Once we receive and confirm your verifiable consumer request, we will disclose to you:
</p>
<ul>
<li>●	The categories of Personal Information we collected about you;</li>
<li>●	The categories of sources for the Personal Information we collected about you;</li>
<li>●	Our business or commercial purpose for collecting that Personal Information;</li>
<li>●	The categories of third parties with whom we share that Personal Information; and</li>
<li>●	The specific pieces of Personal Information we collected about you (also called a “data portability” request).</li>
</ul>
<p>
We generally do not sell User data. However, if we do sell your Personal Information for a business purpose, we will also disclose to you:
</p>
<ul>
<li>●	The Personal Information that each category of recipient purchased; and</li>
<li>●	Disclosures for a business purpose, identifying the Personal Information categories that each category of recipient obtained.</li>
</ul>
<p>
Deletion Request Rights

You have the right to request that we delete any of your Personal Information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your Personal Information from our records, unless an exception applies.

We may deny your deletion request if retaining the information is necessary for us or our service providers to:
</p>
<ul>
<li>●	Complete any transaction for which we collected the Personal Information, provide a product or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you;</li>
<li>●	Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities; </li>
<li>●	Debug products, including our Websites and Services, to identify and repair errors that impair existing intended functionality; </li>
<li>●	Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law; </li>
<li>●	Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.); </li>
<li>●	Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent;</li>
<li>●	Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us; </li>
<li>●	Comply with a legal obligation; or </li>
<li>●	Make other internal and lawful uses of that information that are compatible with the context in which you provided it. </li>
</ul>
<p>
Exercising Access, Data Portability, and Deletion Rights

To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either:
</p>
<ul>
<li>●	Sending us an email at: contact@alistgames.net</li>
<li>●	Sending us a letter at: 49 E Walnut St, Pasadena, CA 91103</li>
</ul>
<p>
Requests must include "California Privacy Rights Request" in the first line of the description and include your name, street address, city, state, and ZIP code.

Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your Personal Information. You may also make a verifiable consumer request on behalf of your minor child.

You may only make a verifiable consumer request for access or data portability twice within a twelve (12) month period. The verifiable consumer request must:
</p>
<ul>
<li>●	Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or an authorized representative.</li>
<li>●	Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li></ul>
<p>
We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you.  Making a verifiable consumer request does not require you to create an account with us.  We will only use Personal Information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.

Response Timing and Format

We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt.  If we require more time (up to ninety (90) days), we will inform you of the reason and extension period in writing. Any disclosures we provide will only cover the twelve (12) month period preceding the verifiable consumer request's receipt.  The response we provide will also explain the reasons we cannot comply with a request, if applicable.  For data portability requests, we will select a format to provide your Personal Information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.

We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.  If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
 

Non-Discrimination

We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
</p>
<ul>
<li>●	Deny you goods or services;</li>
<li>●	Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties;</li>
<li>●	Provide you a different level or quality of goods or services; or</li>
<li>●	Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
</ul> */}
<p>
    ALG Publishing, LLC dba A List Games (“us”, “we”, “ALG”, or alternative
    first-person reference) wants to thank you for your interest in connection
    with our video games, particularly “<em>Hell is Others”</em> and
    “Kingshunt” (available for download on Steam, the Epic Games Store, the
    PlayStation Store and the Microsoft Store on Xbox) (the “Game(s)”), our
    website at <u>https://www.alistgames.net</u> (the “Website”), our products,
    content, events, applications, patches, updates, tools or services (each
    individually a “Service” and collectively the “Services”).). If you are
    here, you have either been directed here through our Services, or through
    our associated social media, including Facebook, Discord, Twitter,
    Instagram, YouTube, Twitch, TikTok and other online communities or
    website(s).
</p>
<p>
    This page informs you of our policies regarding the collection, use, and
    disclosure of personal data when you use our Services, your rights to your
    data under US and International Law, including but not limited to the EU
    Directive 2002/58/EC and the EU General Data Protection Regulation
    (“GDPR”), South Korea’s Personal Information Protection Act (“PIPA”),
    Brazil’s Lei Geral de Proteção de Dados (commonly referred to as the
    “LGPD”), the Controlling the Assault of Non-Solicited Pornography and
    Marketing Act of 2003 (“CAN-SPAM”), the US Children’s Online Privacy
    Protection Act (“COPPA”), and the California Consumer Privacy Act (“CCPA”),
    and the choices you have associated with that data. This Privacy Policy
    applies to the Websites and Services, regardless of how you access or use
    them, including through mobile devices. This Privacy Policy does not apply
    to any third-party websites, services or applications, even if they are
    accessible through our Services.
</p>
<p>
    We use your data to provide and improve the Services. This Privacy Policy
    is solely intended to inform you of our data collection practices and your
    rights to any of your data that we may collect.
</p>
<p>
    This Privacy Policy describes:
</p>
<p>
    ● The information we collect, how we collect such information and the
    purposes of our collection;
</p>
<p>
    ● How we use and with whom we share such information;
</p>
<p>
    ● How you can access and update your information; and
</p>
<p>
    ● How we protect the information we store about you.
</p>
<p>
    <strong> </strong>
</p>
<p>
    <strong>Disclosure to California Residents</strong>
    : In our efforts to comply with applicable regulation, we ask and advise
    that California residents review the “CCPA Disclosure” at the end of this
    document. Although we do not sell User data, we will provide notice if at
    any time those circumstances change.
</p>
<p>
    <strong>Disclosure to Residents of Brazil</strong>
    : The LGPD grants residents of Brazil certain rights about the processing
    of their personal information and we ask and advise that residents of
    Brazil review the relevant section under the “Your Rights” heading of this
    Privacy Policy.
</p>
<h2>
    Definitions
</h2>
<h3>
</h3>
<h3>
    Personal Data
</h3>
<p>
    Personal Data means information relating to an identified or identifiable
    individual. An identifiable individual is one who can be identified,
    directly or indirectly, by reference to an identifier such as a name, a
    device identification number, location data, an online identifier, or to
    one or more factors specific to the physical, physiological, genetic,
    mental, economic, cultural or social identity of that natural person.
</p>
<h3>
    Usage Data
</h3>
<p>
    Usage Data is data collected automatically either generated by the use of
    the Service or from the Service infrastructure itself (for example, the
    duration of a page visit).
</p>
<h3>
    Cookies
</h3>
<p>
    Cookies are small pieces of data stored on a User’s device.
</p>
<h3>
    Data Controller and Data Protection Officer
</h3>
<p>
    Data Controller means a person who (either alone or jointly or in common
    with other persons) determines the purposes for which and the manner in
    which any Personal Data are, or are to be, processed. For the purpose of
    this Privacy Policy, we are a Data Controller of your data. Where we have a
    legal obligation to do so, we have appointed data protection officers
    (DPOs) to be responsible for our privacy program. You can contact our DPO
    via the Contact Us information detailed below in this Privacy Policy.
</p>
<h3>
    Data Processor (or Service Providers)
</h3>
<p>
    Data Processor (or Service Provider) means any person (other than an
    employee of the Data Controller) who processes the data on behalf of the
    Data Controller. We may use the services of various Service Providers in
    order to process your data more effectively.
</p>
<h3>
    Data Subject
</h3>
<p>
    Data Subject is any living individual who is the subject of Personal Data.
</p>
<h3>
    User
</h3>
<p>
    The User is you - the individual using our Service! The User corresponds to
    the Data Subject, who is the subject of Personal Data.
</p>
<h2>
    Information Collection and Use
</h2>
<p>
    We collect different types of information for various purposes to provide
    and improve our Service to you.
</p>
<h3>
</h3>
<h3>
    Types of Data Collected
</h3>
<h4>
    Personal Data
</h4>
<p>
    While using our Services or when contacting us through our Website (for
    example, if you have questions about our Services) we may ask you to
    provide us with certain personally identifiable information that can be
    used to contact or identify you for customer support “Personal Data”).
</p>
<p>
    Personally identifiable information may include, but is not limited to:
</p>
<p>
    ● E-mail addresses for customer support, surveys, playtests and other
    promotional communications. We will also store customer support
    correspondence and any information contained within in order to service
    your customer support requests and maintain a record of error and bug
    resolutions.
</p>
<p>
    ● Name of the platform the User utilizes to access the Games (such as
    Windows, PlayStation or Xbox)
</p>
<p>
    ● Steam ID, PlayStation Network ID, Xbox gamertag, or any first party
    authentication ID we may add to allow Users to sign into the Game)
</p>
<p>
    ● Video game specific username and password
</p>
<p>
    ● Hardware specifications such as CPU vendor and brand, and GPU Brand
</p>
<p>
    ● IP Address
</p>
<p>
    We may also use your Personal Data to contact you with newsletters,
    updates, marketing or promotional materials and other information that may
    be of interest to you in relation to our Services. You may opt in to
    receiving any, or all, of these communications from us by providing your
    e-mail to subscribe to our mailing list.
</p>
<h4>
    Usage Data
</h4>
<p>
    We, or third parties operating through our website (such as analytics
    providers like Google Analytics), may also collect information concerning
    your access and use of the Services (“Usage Data”). This Usage Data may
    include certain technical information about your mobile device or computer
    system such as your computer’s Internet Protocol address (e.g. IP address),
    browser type, browser version, the pages of our Service that you visit, the
    time and date of your visit, the time spent on those pages, system unique
    device identifiers, as well as interactions between you and the Services,
    such as what character you choose, play history, how long you are in a
    match and whether you win or lose, what you click on in the UI, what pages
    in the application you visit and other diagnostic data.
</p>
<h4>
    Location Data
</h4>
<p>
    When you use our Services, we may collect, use and store information about
    your general location by converting your IP address into a rough
    geo-location (“Location Data”) to provide features to our Services, such as
    to provide matchmaking in our Games and for country mapping-purposes to
    display the appropriate currency in your region, and to improve and
    customize our Services.
</p>
<h4>
    Tracking Cookies Data
</h4>
<p>
    We, or third parties acting on our or their own behalf may use cookies and
    similar tracking technologies to track your activity in connection with our
    Services and hold certain information.
</p>
<p>
    Cookies are files with a small amount of data which may include an
    anonymous unique identifier. Cookies are sent to your browser from a
    website and stored on your device. Cookies help us provide the appropriate
    experiences and features of our Services, analyze and improve how the
    Services are being accessed and used, and enable us to track performance of
    the Services. Third parties acting on our behalf may also use cookies to
    gather information about your activities on the Services, and other sites
    you visit, in order to provide you with ads.
</p>
<p>
    Tracking technologies also used are beacons, tags, and scripts to collect
    and track information and to improve and analyze our Service.
</p>
<p>
    You can instruct your browser to refuse all cookies or to indicate when a
    cookie is being sent. However, if you do not accept cookies, you may not be
    able to use some portions of our Services.
</p>
<h2>
    Use of Data
</h2>
<p>
    We use your personal information to provide you with our Services, to
    comply with our legal obligations, protect your vital interest, or as may
    be required for the public good. This includes:
</p>
<p>
    ● To provide and maintain our Services;
</p>
<p>
    ● To notify you about changes to our Services;
</p>
<p>
    ● To allow you to participate in interactive features of our Services when
    you choose to do so and to the extent applicable;
</p>
<p>
    ● To provide customer support; and
</p>
<p>
    ● To prevent, detect, mitigate, and investigate fraud, security breaches or
    other potentially prohibited or illegal activities
</p>
<p>
    We also use your personal information to pursue our legitimate interests
    where your rights and freedoms do not outweigh these interests. We have
    implemented controls to balance our interests with your rights. This
    includes to:
</p>
<p>
    ● To improve gameplay and delivery with regard to our Services;
</p>
<p>
    ● To use generalized location information to provide you with
    location-based services;
</p>
<p>
    ● Gather analysis or valuable information through interaction data and
    measuring business outcomes, performance and behavior so that we can
    improve our Services;
</p>
<p>
    ● Monitor the usage of our Services;
</p>
<p>
    ● Detect, prevent and address technical issues; and
</p>
<p>
    ● To enforce any End User License Agreement and/or Terms of Service with
    respect to our Services, to the extent applicable.
</p>
<p>
    With your consent, we may use your personal information to:
</p>
<p>
    ● Provide you with updates concerning our Game or future titles;
</p>
<p>
    ● Provide you with news, special offers and general information about other
    services, promotions, or events, unless you have opted not to receive such
    information; and
</p>
<p>
    ● Contact you in connection with prior customer support or Services you
    have experienced.
</p>
<p>
    If you have provided your e-mail address to us (e.g., via a customer
    support request, through our playtest signups or in relation to our mailing
    list), we may use it to respond to customer support inquiries or to send
    promotional e-mail messages directly or in partnership with parties other
    than ALG.
</p>
<p>
    Please contact us in the event that you no longer wish to receive such
    communications.
</p>
<h2>
</h2>
<h2>
    Retention of Data
</h2>
<p>
    ALG will retain your Personal Data only for as long as is necessary for the
    purposes set out in this Privacy Policy. We will retain and use your
    Personal Data to the extent necessary to comply with our legal obligations
    (for example, if we are required to retain your data to comply with
    applicable laws), resolve disputes, and enforce our legal agreements and
    policies.
</p>
<p>
    ALG will also retain Usage Data for internal analysis purposes. Usage Data
    is generally retained for a shorter period of time, except when this data
    is used to strengthen the security or to improve the functionality of our
    Services, or we are legally or contractually obligated to retain this data
    for longer time periods.
</p>
<h2>
</h2>
<h2>
    Transfer of Data
</h2>
<p>
    Your information, including Personal Data, may be transferred to — and
    maintained on — cloud storage, or otherwise on computers located outside of
    your state, province, country or other governmental jurisdiction where the
    data protection laws may differ than those from your jurisdiction.
</p>
<p>
    If you are located outside of the United States and choose to provide
    information to us, please note that we transfer the data, including
    Personal Data, to the United States and process it there.
</p>
<p>
    ALG will take all steps reasonably necessary to ensure that your data is
    treated securely and in accordance with this Privacy Policy and no transfer
    of your Personal Data will take place to an organization or a country
    unless there are adequate controls in place, including the security of your
    data and other personal information. The transfer of such data is typically
    for the purpose of fulfilling our contract with you, as specified above.
    Therefore, any request to delete or modify such Personal Data may result in
    the termination or interruption of our Services with respect to your usage
    of such Services.
</p>
<h2>
    Disclosure of Data
</h2>
<h2>
</h2>
<h2>
    Disclosure for Law Enforcement
</h2>
<p>
    Under certain circumstances, ALG may be required to disclose your Personal
    Data if required to do so by law or in response to valid requests by public
    authorities (e.g. a court or a government agency).
</p>
<h3>
    Legal Requirements
</h3>
<p>
    ALG may disclose your Personal Data in the good faith belief that such
    action is necessary to:
</p>
<p>
    ● To comply with a legal obligation;
</p>
<p>
    ● To protect and defend the rights or property of ALG;
</p>
<p>
    ● To prevent or investigate possible wrongdoing in connection with the
    Service;
</p>
<p>
    ● To protect the personal safety of Users of the Service or the public; and
</p>
<p>
    ● To protect against legal liability.
</p>
<h3>
    Change of Control
</h3>
<p>
    If we are subject to a merger or acquisition with/by another company, we
    may share information with them in accordance with our privacy standards.
    Should such an event occur, we will require that the new combined entity
    follow this privacy policy with respect to your Personal Data. If we intend
    to handle your Personal Data for any purposes not covered in this privacy
    notice, you will receive prior notification of the processing of your
    Personal Data for the new purposes.
</p>
<h2>
    Security of Data
</h2>
<p>
    We protect your Personal Data using technical and administrative security
    measures to reduce the risks of loss, misuse, unauthorized access,
    disclosure and alteration. Some of the safeguards we use may include data
    encryption and information access authorization controls. The security of
    your Personal Data is important to us, but remember that no method of
    transmission over the Internet, or method of electronic storage is 100%
    secure. While we strive to use commercially acceptable means to protect
    your Personal Data, we cannot guarantee its absolute security.
</p>
<h2>
    "Do Not Track" Signals
</h2>
<p>
    <strong>California Do Not Track Disclosure</strong>
    <strong>
        <br/>
    </strong>
    In September 2013, California enacted amendments to the California Online
    Privacy Protection Act (California Business &amp; Professions Code
    §§22575-22579) which require operators of websites to disclose how they
    respond to “do not track” (DNT) signals from browsers used by consumers or
    other mechanisms that provide consumers a choice regarding the collection
    of personally identifiable information about the consumer’s online
    activities over time and across different websites or online services. You
    can enable or disable Do Not Track by visiting the Preferences or Settings
    page of your web browser.
</p>
<p>
    While some newer browsers have incorporated “Do Not Track” features which,
    when turned on, send a signal or preference to the websites you visit
    indicating that you do not wish your online activity to be tracked, a DNT
    protocol that can be implemented across multiple devices and sites has not
    yet been widely adopted. Currently, we do not respond, and cannot act on
    DNT signal headers that we may receive; accordingly, the choices that we
    provide you concerning our collection and use of personally identifiable
    information will continue to operate as described in this policy.
</p>
<h2>
    Your Rights
</h2>
<p>
    ALG aims to take reasonable steps to allow you to correct, amend, delete,
    or limit the use of your Personal Data.
</p>
<p>
    You may update, correct, or provide additional information to your Personal
    Data by resubmitting your information or contacting us directly. If you are
    correcting information provided to a third party (such as those identified
    in the “Service Providers” section below) please correct your information
    via the methods and direction provided by the third party.
</p>
<p>
    If you wish to be informed what Personal Data we hold about you and if you
    want it to be removed from our systems, please contact us via any of the
    contact options provided at the end of this Privacy Policy.
</p>
<p>
    You have the right:
</p>
<p>
    ● To know what personal information we maintain about you;
</p>
<p>
    ● To receive a copy of your personal information in a structured, commonly
    used and machine-readable or commonly used electronic format on request;
</p>
<p>
    ● To update and modify personal information that is incorrect or
    incomplete;
</p>
<p>
    ● To object to our processing of your personal information when collection
    is based on a legitimate interest; and
</p>
<p>
    ● To delete or restrict how we use your personal information, but this
    right is determined by applicable law and may impact your access to some of
    our Services.
</p>
<p>
    Please note that we may ask you to verify your identity before responding
    to such requests.
</p>
<h3>
    How to Control Your Information
</h3>
<p>
    Under the EU General Data Protection Regulation, individuals residing in
    the EU and other territories that have adopted GDPR compliance or
    comparable regulation have a right to:
</p>
<p>
    ● Access their User data;
</p>
<p>
    ● Correct the data they have provided to us;
</p>
<p>
    ● Have information provided to them in a portable format;
</p>
<p>
    ● Request the deletion of the data we have collected;
</p>
<p>
    ● Restrict processing; and
</p>
<p>
    ● Object to processing or the legal basis on which we rely to process their
    data.
</p>
<p>
    Please note that we may ask you to verify your identity before responding
    to such requests.
</p>
<p>
    You may send an e-mail to contact@alistgames.net with your request to
    exercise any of the rights identified above. Place the reason for your
    request (e.g. “Delete my Data” or “Correct my Data”) in the subject line
    and include your first name, last name, Platform ID and e-mail address in
    the body of the e-mail. We will respond to your request within thirty (30)
    days. Please note that certain records, for example those pertaining to
    payments or customer service matters, will be retained for legal and
    accounting purposes. If you have sent or posted content through the
    Services, we may not be able to delete it.
    <br/>
    <br/>
    We are unable to delete data controlled solely by third parties; for
    example, if you purchase our Games through the PlayStation Store, we won’t
    be able to delete your Game from your device, nor the data collected by
    Sony in connection with your purchase or your PlayStation Network ID.
    Similarly, if you access any of our Services through third party social
    media or service platforms, such as Twitter, or if you are routed to a
    third-party service provider’s web page through our Services, you will need
    to make any deletion requests to those respective third parties separately.
</p>
<p>
    <strong>Individuals Located in Brazil</strong>
    : The LGPD grants you certain rights about the processing of your personal
    information. In addition to any applicable rights mentioned above, eligible
    residents of Brazil also have the right: (i) to the anonymization,
    blocking, or elimination of your personal information, if you believe we
    are processing your personal information in an unnecessary, exceeding or
    non-compliant manner with the LGPD; (ii) to request the portability of your
    personal information; (iii) to request information about the entities with
    which we have shared your personal information; and (iv) to request
    information about the possibility and consequences of denying consent.
    While our processing of personal information generally does not include
    automated decision-making, you are also entitled to a review of our
    decisions that are made solely based on automated processing of personal
    information that affect your interests. Notwithstanding any other
    references to the contrary within this Privacy Policy, in accordance with
    the LGPD, we will respond to your request within fifteen (15) days after
    receipt and we will provide any requested information free of charge to
    you. You may also file a complaint with the National Data Protection
    Authority in Brazil, the Autoridade Nacional de Proteção de Dados (“ANPD”)
    for alleged violations of the LGPD, in addition to or alternatively to
    seeking judicial redress.
</p>
<h2>
    Social Media Communications
</h2>
<p>
    You may be able to take part in certain activities through our Services
    that give you the opportunity to communicate or share information not just
    with ALG, but also with other Users of our Services, generally by engaging
    with official ALG social media accounts. We are not responsible for your
    use of those services. If you communicate to us or other Users publicly via
    our social media accounts, you are strongly encouraged to review the
    privacy policies of those services if you have questions about the use of
    your data. We only access the information you make available to the public
    on our social media pages and do not receive any information concerning
    your social media account from any social media platform.
</p>
<h2>
    Service Providers
</h2>
<p>
    We may employ third party companies and individuals to facilitate our
    Services (“Service Providers”), to provide the Services on our behalf, to
    perform Service-related services or to assist us in analyzing how our
    Services are used.
</p>
<p>
    These third parties have access to your Personal Data only to perform these
    tasks on our behalf and are obligated not to disclose or use it for any
    other purpose. Some of these tasks include data analysis, hosting services,
    customer service and to assist us in our promotional efforts.
</p>
<h3>
    Web Services, Payment Processing and Analytics
</h3>
<p>
    We may use the following third-party Service Providers to monitor, analyze
    and improve the use of our Service. Please see the links to their
    respective privacy policies as follows:
</p>
<table border="1" cellspacing="0" cellpadding="0" >
    <tbody>
        <tr>
            <td  valign="top">
                <p>
                    Strelka Games
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://www.iubenda.com/privacy-policy/21823212</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    GameAnalytics
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://gameanalytics.com/privacy/</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Azure PlayFab
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://privacy.microsoft.com/en-us/privacystatement</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Gamesight
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://console.gamesight.io/privacy-policy</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Exit Games
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>
                        https://dashboard.photonengine.com/en-US/account/privacyandcookiepolicy
                    </u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Atlassian - Jira
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://www.atlassian.com/legal/privacy-policy</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Steam
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://store.steampowered.com/privacy_agreement/</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Epic Games
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://www.epicgames.com/site/en-US/privacypolicy</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    PlayStation
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>
                        https://www.playstation.com/en-us/legal/privacy-policy/
                    </u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Xbox
                </p>
            </td>
            <td  valign="top">
                <p>
                    https://privacy.microsoft.com/en-us/privacystatement<u></u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Gmail
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://policies.google.com/privacy?gl=US&amp;hl=en</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    <a name="_heading=h.gjdgxs"></a>
                    Twitter
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://twitter.com/privacy</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Discord
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://discordapp.com/privacy</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Facebook
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://www.facebook.com/policy.php</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Twitch
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://www.twitch.tv/p/en/legal/privacy-notice/</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    YouTube
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>https://policies.google.com/privacy</u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    TikTok
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>
                        https://www.tiktok.com/legal/privacy-policy-us?lang=en
                    </u>
                </p>
            </td>
        </tr>
        <tr>
            <td  valign="top">
                <p>
                    Instagram
                </p>
            </td>
            <td  valign="top">
                <p>
                    <u>
                        https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect
                    </u>
                </p>
            </td>
        </tr>
    </tbody>
</table>
<h2>
</h2>
<h2>
    Links to Other Sites
</h2>
<p>
    Our Service may contain links to other sites that are not operated by us.
    If you click on a third-party link, you will be directed to that third
    party's site. We strongly advise you to review the Privacy Policy of every
    site you visit. We have no control over and assume no responsibility for
    the content, privacy policies or practices of any third-party sites or
    services.
</p>
<h2>
    Children’s Privacy
</h2>
<p>
    Our Services are intended for a general audience and are not intended for
    children under the age of 13, or under the applicable age of majority in
    your country of residence. If we become aware that we have unknowingly
    collected Personal Data from children, we take steps to remove that
    information from our servers. We urge parents to instruct their children to
    never give out their real names, addresses or phone numbers without
    permission. We recognize a special obligation to protect Personal Data
    obtained from young children and strive to comply with the Children’s
    Online Privacy Protection Act of 1998, the US law that protects the privacy
    and information of children. We strongly encourage parents and guardians to
    learn more about this important regulation. For more information, you can
    visit: <u>https://epic.org/privacy/kids/#introduction</u>.
</p>
<p>
    We understand that children may also use our website without directly
    providing Personal Data. We may, directly or through a third-party service
    provider, use javascript, cookies, web beacons, and other similar
    technologies described above to collect information about your or your
    child's use of the Services. We use these technologies for measurement,
    analytics, and to improve our Services. For example, we use these
    technologies to help us recognize your or your child's browser as a
    previous User and save any preferences that may have been set; to help us
    customize the content shown while you use, or your child uses the Services
    and to help measure and research the effectiveness of our Services. We may
    also aggregate your or your child's information with information about
    other Users in a way that does not directly identify you or your child, and
    may share aggregated or de-identified information with third parties,
    including to help us develop, analyze and improve content and Services. It
    is possible that the third parties may use this aggregated or de-identified
    information to develop their own products and service offerings.
</p>
<p>
    We may also allow third parties to place and read their own cookies, web
    beacons, and similar technologies to collect information through the
    Services, including to help us with traffic measurement, research, and
    analytics. We permit these third parties only to collect cookie information
    and similar identifiers that do not, on their own, directly identify you or
    your child. You may be able to refuse or disable cookies by adjusting your
    web browser settings. Because each web browser is different, please consult
    the instructions provided by your web browser (typically in the "help"
    section). If you choose to refuse, disable, or delete these technologies,
    some of the functionality of our Services may no longer be available to you
    or your child.
</p>
<h2>
    Changes to This Privacy Policy
</h2>
<p>
    We may update our Privacy Policy from time to time. We will notify you of
    any changes by posting the new Privacy Policy on this page. We will let you
    know via e-mail and/or a prominent notice on our Service, prior to the
    change becoming effective and update the "effective date" at the top of
    this Privacy Policy.
</p>
<p>
    You are advised to review this Privacy Policy periodically for any changes.
    Changes to this Privacy Policy are effective when they are posted on this
    page.
</p>
<h2>
</h2>
<h2>
    Contact Us
</h2>
<p>
    If you have any questions about this Privacy Policy, please contact us:
</p>
<p>
    ● Reach our Data Protection Officer by e-mail: contact@alistgames.net
</p>
<p>
    ● By visiting this page on our website: https://www.alistgames.net/contact
</p>
<p>
    ● Or by mail at: 49 E Walnut St, Pasadena, CA 91103
</p>
<br clear="all"/>
<div>
    <p>
        <strong>California Consumer Data Rights</strong>
    </p>
</div>
<p>
    A List Games complies with the California Consumer Privacy Act of 2018
    (CCPA), effective January 1, 2020. This landmark piece of legislation
    secures new privacy rights for California consumers.
</p>
<h1>
    Categories of Data We Collect and Their Uses
</h1>
<p>
    In addition to the details provided in our Privacy Policy, we collect
    information that identifies, relates to, describes, references, is capable
    of being associated with, or could reasonably be linked, directly or
    indirectly, with a particular consumer or device (“Personal Information”).
    In particular, we have collected the following categories of Personal
    Information from consumers within the last twelve (12) months:
</p>
<table border="1" cellspacing="0" cellpadding="0" width="613">
    <tbody>
        <tr>
            <td>
                <p>
                    <strong>Category</strong>
                </p>
            </td>
            <td width="180">
                <p>
                    <strong>Examples</strong>
                </p>
            </td>
            <td width="72">
                <p>
                    <strong>Collected</strong>
                </p>
            </td>
            <td width="156">
                <p>
                    <strong>Reason for Collection</strong>
                </p>
            </td>
            <td>
                <p>
                    <strong>
                        Is Information “Sold” to 3<sup>rd</sup> Pty?
                    </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Identifiers.
                </p>
            </td>
            <td width="180">
                <p>
                    A real name, alias, postal address, unique personal
                    identifier, online identifier, Internet Protocol address,
                    e-mail address, account name, Social Security number,
                    driver's license number, passport number, or other similar
                    identifiers.
                </p>
            </td>
            <td width="72">
                <p>
                    Yes
                </p>
            </td>
            <td width="156">
                <p>
                    E-mail addresses for customer support and promotional
                    communications, Steam ID, PlayStation Network ID, Xbox
                    gamertag or any first party authentication ID we may add to
                    allow Users to sign into the Game), IP addresses for
                    in-Game matchmaking services, name of the platform the User
                    utilizes to access the Games (such as Windows, PlayStation
                    or Xbox), and hardware specifications such as CPU vendor
                    and brand, and GPU Brand.
                </p>
            </td>
            <td>
                <p>
                    No
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Personal information categories listed in the California
                    Customer Records statute (Cal. Civ. Code § 1798.80(e)).
                </p>
            </td>
            <td width="180">
                <p>
                    A name, signature, Social Security number, physical
                    characteristics or description, address, telephone number,
                    passport number, driver’s license or state identification
                    card number, insurance policy number, education,
                    employment, employment history, bank account number, credit
                    card number, debit card number, or any other financial
                    information, medical information, or health insurance
                    information. Some personal information included in this
                    category may overlap with other categories.
                </p>
            </td>
            <td width="72">
                <p>
                    No
                </p>
            </td>
            <td width="156">
                <p>
                    N/A
                </p>
            </td>
            <td>
                <p>
                    N/A
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Protected classification characteristics under California
                    or federal law.
                </p>
            </td>
            <td width="180">
                <p>
                    Age (40 years or older), race, color, ancestry, national
                    origin, citizenship, religion or creed, marital status,
                    medical condition, physical or mental disability, sex
                    (including gender, gender identity, gender expression,
                    pregnancy or childbirth and related medical conditions),
                    sexual orientation, veteran or military status, genetic
                    information (including familial genetic information).
                </p>
            </td>
            <td width="72">
                <p>
                    No
                </p>
            </td>
            <td width="156">
                <p>
                    N/A
                </p>
            </td>
            <td>
                <p>
                    N/A
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Commercial information.
                </p>
            </td>
            <td width="180">
                <p>
                    Records of personal property, products or services
                    purchased, obtained, or considered, or other purchasing or
                    consuming histories or tendencies.
                </p>
            </td>
            <td width="72">
                <p>
                    No
                </p>
            </td>
            <td width="156">
                <p>
                    N/A
                </p>
            </td>
            <td>
                <p>
                    N/A
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Biometric information.
                </p>
            </td>
            <td width="180">
                <p>
                    Genetic, physiological, behavioral, and biological
                    characteristics, or activity patterns used to extract a
                    template or other identifier or identifying information,
                    such as, fingerprints, faceprints, and voiceprints, iris or
                    retina scans, keystroke, gait, or other physical patterns,
                    and sleep, health, or exercise data.
                </p>
            </td>
            <td width="72">
                <p>
                    No
                </p>
            </td>
            <td width="156">
                <p>
                    N/A
                </p>
            </td>
            <td>
                <p>
                    N/A
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Internet or other similar network activity.
                </p>
            </td>
            <td width="180">
                <p>
                    Browsing history, search history, information on a
                    consumer’s interaction with a website, application, or
                    advertisement.
                </p>
            </td>
            <td width="72">
                <p>
                    Yes
                </p>
            </td>
            <td width="156">
                <p>
                    To determine if a visitor to the Website or viewers of ads
                    purchase a product.
                </p>
            </td>
            <td>
                <p>
                    No
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Geolocation data.
                </p>
            </td>
            <td width="180">
                <p>
                    Physical location or movements.
                </p>
            </td>
            <td width="72">
                <p>
                    Yes
                </p>
            </td>
            <td width="156">
                <p>
                    IP addresses are converted into a rough geo-location data
                    (region) to provide in-Game matchmaking services.
                </p>
            </td>
            <td>
                <p>
                    No
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Sensory data.
                </p>
            </td>
            <td width="180">
                <p>
                    Audio, electronic, visual, thermal, olfactory, or similar
                    information.
                </p>
            </td>
            <td width="72">
                <p>
                    No
                </p>
            </td>
            <td width="156">
                <p>
                    N/A
                </p>
            </td>
            <td>
                <p>
                    N/A
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Professional or employment-related information.
                </p>
            </td>
            <td width="180">
                <p>
                    Current or past job history or performance evaluations.
                </p>
            </td>
            <td width="72">
                <p>
                    No
                </p>
            </td>
            <td width="156">
                <p>
                    N/A
                </p>
            </td>
            <td>
                <p>
                    N/A
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Non-public education information (per the Family
                    Educational Rights and Privacy Act (20 U.S.C. Section
                    1232g, 34 C.F.R. Part 99)).
                </p>
            </td>
            <td width="180">
                <p>
                    Education records directly related to a student maintained
                    by an educational institution or party acting on its
                    behalf, such as grades, transcripts, class lists, student
                    schedules, student identification codes, student financial
                    information, or student disciplinary records.
                </p>
            </td>
            <td width="72">
                <p>
                    No
                </p>
            </td>
            <td width="156">
                <p>
                    N/A
                </p>
            </td>
            <td>
                <p>
                    N/A
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Inferences drawn from other personal information.
                </p>
            </td>
            <td width="180">
                <p>
                    Profile reflecting a person's preferences, characteristics,
                    psychological trends, predispositions, behavior, attitudes,
                    intelligence, abilities, and aptitudes.
                </p>
            </td>
            <td width="72">
                <p>
                    No
                </p>
            </td>
            <td width="156">
                <p>
                    N/A
                </p>
            </td>
            <td>
                <p>
                    N/A
                </p>
            </td>
        </tr>
    </tbody>
</table>
<p>
    Personal Information does not include:
</p>
<p>
    ● Publicly available information from government records.
</p>
<p>
    ● De-identified or aggregated consumer information.
</p>
<p>
    ● Information excluded from the CCPA's scope, like:
</p>
<p>
    o Health or medical information covered by the Health Insurance Portability
    and Accountability Act of 1996 (HIPAA) and the California Confidentiality
    of Medical Information Act (CMIA) or clinical trial data;
</p>
<p>
    o Personal information covered by certain sector-specific privacy laws,
    including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act
    (GLBA) or California Financial Information Privacy Act (FIPA), and the
    Driver's Privacy Protection Act of 1994.
</p>
<p>
    We obtain the categories of Personal Information listed above from the
    following categories of sources:
</p>
<p>
    ● Directly from our Users. For example, from customers who engage us for
    customer support or provide customer feedback.
</p>
<p>
    ● Indirectly from our Users. For example, through information we collect
    from our Users in the course of providing customer support to them.
</p>
<p>
    ● Directly and indirectly from activity on our Websites or through the
    Services. For example, from APIs and cookies (described above) embedded in
    our Websites, as applicable, and other information related to improving our
    products.
</p>
<p>
    ● From third parties that interact with us in connection with the Services
    we perform.
</p>
<p>
    All such Personal Information is used as otherwise described above in our
    Privacy Policy.
</p>
<h1>
</h1>
<h1>
</h1>
<h1>
    Sharing Personal Information
</h1>
<p>
    We may disclose your Personal Information to a third party for a business
    purpose. When we disclose Personal Information for a business purpose, we
    enter into a contract that describes the purpose and requires the recipient
    to both keep that Personal Information confidential and not use it for any
    purpose except performing the contract.
</p>
<h1>
</h1>
<h1>
</h1>
<h1>
    Your Rights and Choices
</h1>
<p>
    <strong><em> </em></strong>
</p>
<p>
    <strong>Access to Specific Information and Data Portability Rights</strong>
    <strong></strong>
</p>
<p>
    You have the right to request that we disclose certain information to you
    about our collection and use of your Personal Information over the past
    twelve (12) months. Once we receive and confirm your verifiable consumer
    request, we will disclose to you:
</p>
<p>
    ● The categories of Personal Information we collected about you;
</p>
<p>
    ● The categories of sources for the Personal Information we collected about
    you;
</p>
<p>
    ● Our business or commercial purpose for collecting that Personal
    Information;
</p>
<p>
    ● The categories of third parties with whom we share that Personal
    Information; and
</p>
<p>
    ● The specific pieces of Personal Information we collected about you (also
    called a “data portability” request).
</p>
<p>
    We generally do not sell User data. However, if we do sell your Personal
    Information for a business purpose, we will also disclose to you:
</p>
<p>
    ● The Personal Information that each category of recipient purchased; and
</p>
<p>
    ● Disclosures for a business purpose, identifying the Personal Information
    categories that each category of recipient obtained.
</p>
<p>
    <strong>Deletion Request Rights</strong>
    <strong></strong>
</p>
<p>
    You have the right to request that we delete any of your Personal
    Information that we collected from you and retained, subject to certain
    exceptions. Once we receive and confirm your verifiable consumer request,
    we will delete (and direct our service providers to delete) your Personal
    Information from our records, unless an exception applies.
</p>
<p>
    We may deny your deletion request if retaining the information is necessary
    for us or our service providers to:
</p>
<p>
    ● Complete any transaction for which we collected the Personal Information,
    provide a product or service that you requested, take actions reasonably
    anticipated within the context of our ongoing business relationship with
    you, or otherwise perform our contract with you;
</p>
<p>
    ● Detect security incidents, protect against malicious, deceptive,
    fraudulent, or illegal activity, or prosecute those responsible for such
    activities;
</p>
<p>
    ● Debug products, including our Websites and Services, to identify and
    repair errors that impair existing intended functionality;
</p>
<p>
    ● Exercise free speech, ensure the right of another consumer to exercise
    their free speech rights, or exercise another right provided for by law;
</p>
<p>
    ● Comply with the California Electronic Communications Privacy Act (Cal.
    Penal Code § 1546 seq.);
</p>
<p>
    ● Engage in public or peer-reviewed scientific, historical, or statistical
    research in the public interest that adheres to all other applicable ethics
    and privacy laws, when the information's deletion may likely render
    impossible or seriously impair the research's achievement, if you
    previously provided informed consent;
</p>
<p>
    ● Enable solely internal uses that are reasonably aligned with consumer
    expectations based on your relationship with us;
</p>
<p>
    ● Comply with a legal obligation; or
</p>
<p>
    ● Make other internal and lawful uses of that information that are
    compatible with the context in which you provided it.
</p>
<br clear="all"/>
<p>
    <strong> </strong>
</p>
<p>
    <strong>Exercising Access, Data Portability, and Deletion Rights</strong>
    <strong></strong>
</p>
<p>
    To exercise the access, data portability, and deletion rights described
    above, please submit a verifiable consumer request to us by either:
</p>
<p>
    ● Sending us an email at: contact@alistgames.net
</p>
<p>
    ● Sending us a letter at: 49 E Walnut St, Pasadena, CA 91103
</p>
<p>
    Requests must include "California Privacy Rights Request" in the first line
    of the description and include your name, street address, city, state, and
    ZIP code.
</p>
<p>
    Only you or a person registered with the California Secretary of State that
    you authorize to act on your behalf, may make a verifiable consumer request
    related to your Personal Information. You may also make a verifiable
    consumer request on behalf of your minor child.
</p>
<p>
    You may only make a verifiable consumer request for access or data
    portability twice within a twelve (12) month period. The verifiable
    consumer request must:
</p>
<p>
    ● Provide sufficient information that allows us to reasonably verify you
    are the person about whom we collected Personal Information or an
    authorized representative.
</p>
<p>
    ● Describe your request with sufficient detail that allows us to properly
    understand, evaluate, and respond to it.
</p>
<p>
    We cannot respond to your request or provide you with Personal Information
    if we cannot verify your identity or authority to make the request and
    confirm the Personal Information relates to you. Making a verifiable
    consumer request does not require you to create an account with us. We will
    only use Personal Information provided in a verifiable consumer request to
    verify the requestor's identity or authority to make the request.
</p>
<p>
    <strong>Response Timing and Format</strong>
    <strong></strong>
</p>
<p>
    We endeavor to respond to a verifiable consumer request within forty-five
    (45) days of its receipt. If we require more time (up to ninety (90) days),
    we will inform you of the reason and extension period in writing. Any
    disclosures we provide will only cover the twelve (12) month period
    preceding the verifiable consumer request's receipt. The response we
    provide will also explain the reasons we cannot comply with a request, if
    applicable. For data portability requests, we will select a format to
    provide your Personal Information that is readily useable and should allow
    you to transmit the information from one entity to another entity without
    hindrance.
</p>
<p>
    We do not charge a fee to process or respond to your verifiable consumer
    request unless it is excessive, repetitive, or manifestly unfounded. If we
    determine that the request warrants a fee, we will tell you why we made
    that decision and provide you with a cost estimate before completing your
    request.
</p>
<br clear="all"/>
<p>
    <strong>Non-Discrimination</strong>
    <strong></strong>
</p>
<p>
    We will not discriminate against you for exercising any of your CCPA
    rights. Unless permitted by the CCPA, we will not:
</p>
<p>
    ● Deny you goods or services;
</p>
<p>
    ● Charge you different prices or rates for goods or services, including
    through granting discounts or other benefits, or imposing penalties;
</p>
<p>
    ● Provide you a different level or quality of goods or services; or
</p>
<p>
    ● Suggest that you may receive a different price or rate for goods or
    services or a different level or quality of goods or services.
</p>

              </Box>
            </Box>
          </ArtDecoContainer>
      </Box>
    </Section >
    </Box>
  </Layout>
)}

export const Head = () => <Seo title="Privacy" />

export default PrivacyPage

